import CampaignBuilder from "../../assets/image/mark/campaign-builder-templates.svg";
import AdsetBuilder from "../../assets/image/mark/adset-builder.svg";
import PerfectAds from "../../assets/image/mark/perfect-ads.svg";
//import ManageCampaigns from "../../assets/image/mark/manage-campaigns.svg";
import ManageCampaigns from "../../assets/image/mark/campaign-builder.svg";

import AuthorOne from "../../assets/image/mark/author-1.jpg";
import AuthorTwo from "../../assets/image/mark/author-2.jpg";
import AuthorThree from "../../assets/image/mark/author-3.jpg";

import News1 from "../../assets/image/mark/blog/ultimate-guide-facebook-advertising-cost-in-2020.png";
import News2 from "../../assets/image/mark/blog/Increase-shopify-conversion-rate.png";
import News3 from "../../assets/image/mark/blog/connect-shopify-to-google-analytics.jpg";

import Member1 from "../../assets/image/mark/team/selim-chehimi.jpg";
import Member2 from "../../assets/image/mark/team/kilian-poulin.jpg";

export const MENU_ITEMS = [
  /*{
    label: "Home",
    path: "#banner_section",
    offset: "0",
  },*/
  {
    label: "Screenshots",
    path: "#screenshot_section",
    offset: "0",
  },
  {
    label: "Feature",
    path: "#feature_section",
    offset: "0",
  },
  {
    label: "Pricing",
    path: "#pricing_section",
    offset: "0",
  },
  /*{
    label: "Testimonial",
    path: "#testimonial_section",
    offset: "0",
  },*/
  {
    label: "Team",
    path: "#team_section",
    offset: "0",
  },
  {
    label: "Blog",
    path: "/blog",
    offset: "0",
    siteLink: true,
  },
];

export const SERVICE_ITEMS = [
  {
    icon: "flaticon-stopwatch-1",
    title: "Fast Performance",
  },
  {
    icon: "flaticon-group",
    title: "Ready-to-use audiences",
  },
  {
    icon: "flaticon1-artificial-intelligence",
    title: "E-commerce optimization",
  },
  {
    icon: "flaticon1-ads",
    title: "Live Preview",
  },
  {
    icon: "flaticon-startup",
    title: "Launch on Ad platforms",
  },
];

export const MONTHLY_PRICING_TABLE = [
  {
    name: "Starter",
    description: "It's for you if you manage only one store",
    price: "$29.00",
    priceLabel: "Per month",
    buttonLabel: "Start Free Trial",
    url: `${process.env.APP_URL}/sign-up/beta`,
    listItems: [
      {
        content: "1 Shopify store",
      },
      {
        content: "Ready-to-use audience templates",
      },
      {
        content: "Automated rules",
      },
      {
        content: "Products imported automatically",
      },
      {
        content: "Creative crop tool",
      },
    ],
  },
  {
    name: "Hustler",
    description: "It's for you if you manage more than one store",
    price: "$99.00",
    priceLabel: "Per month",
    buttonLabel: "Start Free Trial",
    url: "#access_section",
    trialButtonLabel: "",
    trialURL: "#",
    listItems: [
      {
        content: "Up to 5 Shopify stores",
      },
      {
        content: "Ready-to-use audience templates",
      },
      {
        content: "Automated rules",
      },
      {
        content: "Products imported automatically",
      },
      {
        content: "Creative crop tool",
      },
    ],
  },
  {
    name: "Pro",
    description: "For Large teams or group who need to build website ",
    price: "$199.00",
    priceLabel: "Per month",
    buttonLabel: "Start Free Trial",
    url: "#access_section",
    trialButtonLabel: "",
    trialURL: "#",
    listItems: [
      {
        content: "Up to 10 Shopify stores",
      },
      {
        content: "$25,000/month in ad spend",
      },
      {
        content: "Ready-to-use audience templates",
      },
      {
        content: "Automated rules",
      },
      {
        content: "Products imported automatically",
      },
    ],
  },
];

export const YEARLY_PRICING_TABLE = [
  {
    name: "Starter",
    description: "It's for you if you manage only one store",
    price: "$24.00",
    priceLabel: "Per month & subscription yearly",
    buttonLabel: "Start Free Trial",
    url: "#access_section",
    listItems: [
      {
        content: "1 Shopify store",
      },
      {
        content: "Ready-to-use audience templates",
      },
      {
        content: "Automated rules",
      },
      {
        content: "Products imported automatically",
      },
      {
        content: "Creative crop tool",
      },
    ],
  },
  {
    name: "Hustler",
    description: "It's for you if you manage more than one store",
    price: "$83.00",
    priceLabel: "Per month & subscription yearly",
    buttonLabel: "Start Free Trial",
    url: "#access_section",
    trialButtonLabel: "",
    trialURL: "#",
    listItems: [
      {
        content: "Up to 5 Shopify stores",
      },
      {
        content: "Ready-to-use audience templates",
      },
      {
        content: "Automated rules",
      },
      {
        content: "Products imported automatically",
      },
      {
        content: "Creative crop tool",
      },
    ],
  },
  {
    name: "Pro",
    description: "For Large teams or group who need to build website ",
    price: "$166.00",
    priceLabel: "Per month & subscription yearly",
    buttonLabel: "Start Free Trial",
    url: `${process.env.APP_URL}/sign-up/beta`,
    trialButtonLabel: "",
    trialURL: "#",
    listItems: [
      {
        content: "Up to 10 Shopify stores",
      },
      {
        content: "$25,000/month in ad spend",
      },
      {
        content: "Ready-to-use audience templates",
      },
      {
        content: "Automated rules",
      },
      {
        content: "Products imported automatically",
      },
    ],
  },
];

export const FAQ_DATA = [
  {
    id: 1,
    expend: true,
    title: "How to contact Customer Service?",
    description:
      "Our Customer Experience Team is available 7 days a week on the Chat. We reply quickly, so you don't hesitate to reach to us if you need anything! ",
  },
  {
    id: 2,
    title: "App integration with Shopify failed, what to do ?",
    description:
      "Please contact us on the Chat, our team will solve the issue in minutes. ",
  },
  {
    id: 3,
    title: "How often are new features released ?",
    description:
      "We deliver new features as soon as they are ready, expect to see many changes in your first month of subscription!",
  },
  {
    id: 4,
    title: "How to join the Beta ?",
    description:
      "Contact us on the Chat or just schedule a call with our team. We will then reply with all the details you need.",
  },
];

export const FOOTER_WIDGET = [
  {
    title: "About Us",
    menuItems: [
      {
        url: "#",
        text: "Support Center",
      },
      {
        url: "#",
        text: "Customer Support",
      },
      {
        url: "#team_section",
        text: "About Us",
      },
      {
        url: "#",
        text: "Copyright",
      },
      {
        url: "#",
        text: "Popular Campaign",
      },
    ],
  },
  {
    title: "Our Information",
    menuItems: [
      {
        url: "/privacy-policy",
        text: "Privacy Policy",
      },
      {
        url: "#",
        text: "Terms & Conditions",
      },
      {
        url: "#",
        text: "Refund Policy",
      },
      {
        url: "#",
        text: "Site Map",
      },
    ],
  },
  {
    title: "Media",
    menuItems: [
      {
        url:
          "https://www.linkedin.com/posts/efrei-paris_startup-efreiparis-onlyefreiparis-activity-6719539523539337216-pv5q",
        text: "Pepite Award - Efrei Paris",
      },
      {
        url:
          "https://www.enseignementsup-recherche.gouv.fr/cid154439/prix-pepite-2020-une-nouvelle-generation-d-entrepreneurs-engages-solidaires-creatifs.html",
        text: "France Minister of Higher Education, Research and Innovation",
      },
      {
        url:
          "https://www.linkedin.com/posts/efrei-entrepreneurs_f%C3%A9licitations-%C3%A0-la-startup-mark-championne-activity-6719286960311062528-Xucx",
        text: "Efrei Paris Incubator",
      },
      {
        url:
          "https://www.linkedin.com/posts/p%C3%A9pite-cr%C3%A9aj-idf-374aa0174_prix-p%C3%A9pite-%C3%AEle-de-france-2020-teaser-activity-6722134396322775040-Q4Jq",
        text: "Pépite Créaj IDF",
      },
    ],
  },
  {
    title: "Social",
    menuItems: [
      {
        url: "https://www.linkedin.com/company/mark-ai",
        icon: "flaticon1-linkedin-logo",
        text: "LinkedIn",
      },
      {
        url: "https://www.facebook.com/MARK-AI-105990334549670/",
        icon: "flaticon-facebook-logo",
        text: "Facebook",
      },
      {
        url: "https://twitter.com/Mark91683335",
        icon: "flaticon-twitter-logo-silhouette",
        text: "Twitter",
      },
    ],
  },
];

export const FEATURES = [
  {
    icon: "flaticon1-startup",
    title: "Fastest campaign builder",
    description:
      "You won't find any competing platform where you can launch ad campaigns so fast.",
  },
  {
    icon: "flaticon-trophy-1",
    title: "Pepite Award Winner",
    description:
      "Mark has already been awarded Pepite 2020 Champion in France, recognized as a very promising project",
  },
  {
    icon: "flaticon1-shop",
    title: "Top-level Integrations",
    description:
      "We make the most of our integrations, helping you save time and money.",
  },
  {
    icon: "flaticon1-artificial-intelligence",
    title: "AI and features with strong added value",
    description:
      "Our team of developers only focus on technology that truely makes your work easier and better.",
  },
  {
    icon: "flaticon-strategy",
    title: "All-in-One",
    description: "What if you could replace all your tools by a single one ?",
  },
  {
    icon: "flaticon-conversation",
    title: "Customer Support",
    description:
      "You will always find someone ready to listen, analyze and solve your questions or issues.",
  },
];

export const SCREENSHOTS = [
  {
    icon: "flaticon1-artifical-intelligence",
    title: "Select Audiences",
    image: CampaignBuilder,
  },
  {
    icon: "flaticon1-startup",
    title: "Configure Adsets",
    image: AdsetBuilder,
  },
  {
    icon: "flaticon1-ads",
    title: "Create perfect Ads",
    image: PerfectAds,
  },
  {
    icon: "flaticon-strategy",
    title: "Manage Campaigns",
    image: ManageCampaigns,
  },
];

export const TESTIMONIALS = [
  {
    title: "Awesome tool!",
    review:
      "I stopped using Facebook ads manager and switched to Mark, it's fast and easy to use.",
    name: "Owen Cazneaux",
    designation: "Shopify store owner",
    avatar: `${AuthorOne}`,
  },
  {
    title: "#1 platform for audiences",
    review:
      "Mark is the best platform for ready-to-use audiences, it saves me so much time on every campaign!",
    name: "Wiktoria Adamczyk",
    designation: "Shopify store owner",
    avatar: `${AuthorTwo}`,
  },
  {
    title: "Perfect campaign builder",
    review:
      "It's just so easy to use, I don't even need to import my products, great job on the integration!",
    name: "Rafael Ribeiro",
    designation: "Shopify store owner",
    avatar: `${AuthorThree}`,
  },
];

export const TEAM_MEMBER = [
  {
    id: 1,
    name: "Selim Chehimi",
    thumbnail_url: Member1,
    designation: "CEO & Co Founder",
    social_links: [
      {
        id: 1,
        icon: "flaticon1-linkedin-logo",
        url: "https://linkedin.com/in/selimchehimi",
      },
      {
        id: 1,
        icon: "flaticon-twitter-logo-silhouette",
        url: "https://twitter.com/selimchehimi",
      },
      {
        id: 1,
        icon: "flaticon-instagram",
        url: "https://www.instagram.com/selimchehimi",
      },
      {
        id: 1,
        icon: "flaticon-youtube",
        url: "https://www.youtube.com/user/TheHomerEro",
      },
    ],
  },
  {
    id: 2,
    name: "Kilian Poulin",
    thumbnail_url: Member2,
    designation: "CTO & Co Founder",
    social_links: [
      {
        id: 1,
        icon: "flaticon1-linkedin-logo",
        url: "https://linkedin.com/in/kilian-poulin-0b943713",
      },
      {
        id: 1,
        icon: "flaticon-instagram",
        url: "https://www.instagram.com/kilianpoulin",
      },
    ],
  },
  /*
  {
    id: 3,
    name: "Handdy Albuzz",
    thumbnail_url: Member3,
    designation: "Article Writer",
    social_links: [
      {
        id: 1,
        icon: "flaticon-facebook-logo",
        url: "#",
      },
      {
        id: 1,
        icon: "flaticon-twitter-logo-silhouette",
        url: "#",
      },
      {
        id: 1,
        icon: "flaticon-instagram",
        url: "#",
      },
      {
        id: 1,
        icon: "flaticon-dribble-logo",
        url: "#",
      },
    ],
  },*/
];

export const NEWS_FEED = [
  {
    id: 1,
    image: News1,
    title: "The Ultimate Guide to Facebook Advertising Cost in 2020",
    excerpt:
      "After reading this article, you will have a better overview of Facebook Ads in 2020.",
    link: "/blog/facebook-advertising-costs",
  },
  {
    id: 2,
    image: News2,
    title: "Ultimate Guide To Increase Your Shopify Conversion Rate",
    excerpt:
      "The struggle to increase your Shopify conversion rate comes from too much informationa available.",
    link: "/blog/ultimate-guide-to-increase-your-shopify-conversion-rate",
  },
  {
    id: 3,
    image: News3,
    title: "How To Connect Google Analytics to your Shopify Store [Guide]",
    excerpt:
      "As an eCommerce owner, you probably want to get more insights into your traffic.",
    link: "/blog/how-to-connect-google-analytics-to-your-shopify-store-guide",
  },
];
