import React from "react";

import BannerSection from "../containers/Mark/Banner";
import ServiceSection from "../containers/Mark/Service";
import PricingSection from "../containers/Mark/Pricing";
import PartnerSection from "../containers/Mark/Partner";
import TrialSection from "../containers/Mark/Trial";
import FeatureSection from "../containers/Mark/Feature";
import UpdateScreen from "../containers/Mark/UpdateScreen";
import Newsletter from "../containers/Mark/Newsletter";
import TeamSection from "../containers/Mark/TeamSection";
import NewsFeed from "../containers/Mark/NewsFeed";
import FaqSection from "../containers/Mark/FaqSection";
import CookieBanner from "../components/GDPR/CookieBanner";
import "@redq/reuse-modal/es/index.css";
import SEO from "../components/seo";

export default () => {
  return (
    <React.Fragment>
      <SEO title="MARK AI - The platform that makes your Facebook Ads profitable" />

      <BannerSection />
      <ServiceSection />
      <UpdateScreen />
      <TrialSection />
      <Newsletter position="top" />
      <FeatureSection />
      <PricingSection />
      <PartnerSection />
      <TeamSection />
      <TrialSection />
      <Newsletter position="bottom" />
      <NewsFeed />
      <FaqSection />
      <CookieBanner />
    </React.Fragment>
  );
};
