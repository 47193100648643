import React from "react";
import PropTypes from "prop-types";
import Box from "../../../../common/src/components/Box";
import Heading from "../../../../common/src/components/Heading";
import Image from "../../../../common/src/components/Image";
import Container from "../../../../common/src/components/UI/Container";

import SectionWrapper from "./trial.style";
import FreeTrial from "../../../../common/src/assets/image/mark/free-trial.svg";

const TrialSection = ({ row, title, textArea, imageArea, ImageOne }) => {
  return (
    <SectionWrapper className="trial-section" id="access_section">
      <Container>
        <Box {...row}>
          <Box {...imageArea}>
            <Image {...ImageOne} src={FreeTrial} alt="VendorLogos" />
          </Box>
          <Box {...textArea}>
            <Heading
              {...title}
              content="Start your 14 days free trial today!"
            />
          </Box>
        </Box>
      </Container>
    </SectionWrapper>
  );
};

TrialSection.propTypes = {
  row: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  imageArea: PropTypes.object,
  ImageOne: PropTypes.object,
};

TrialSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "center",
  },
  textArea: {
    width: ["100%", "100%", "80%", "43%"],
  },
  imageArea: {
    width: ["100%", "100%", "43%"],
    mb: ["35px", "35px", "40px", "40px"],
    flexBox: true,
    justifyContent: "center",
  },
  title: {
    fontSize: ["28px", "32px", "36px", "42px", "48px"],
    fontWeight: "500",
    color: "headingColor",
    letterSpacing: "-0.025em",
    mb: "28px",
    textAlign: "center",
    lineHeight: "1.25",
  },
  description: {
    fontSize: ["15px", "15px", "16px", "16px", "16px"],
    color: "#5c636c",
    lineHeight: "2.1",
    textAlign: "center",
    mb: ["35px", "35px", "40px", "60px"],
  },
  ImageOne: {
    ml: "auto",
    mr: "auto",
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: "center",
  },
  btnStyle: {
    minWidth: "156px",
    fontSize: ["13px", "14px"],
    fontWeight: "500",
    colors: "secondaryWithBg",
    pl: ["15px", "30px"],
    pr: ["15px", "30px"],
  },
  outlineBtnStyle: {
    minWidth: "156px",
    fontSize: "16px",
    fontWeight: "500",
    color: "#0f2137",
    colors: "secondary",
  },
};

export default TrialSection;
