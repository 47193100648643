import React, { Fragment } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import PropTypes from "prop-types";
import Box from "../../../../common/src/components/Box";
import Text from "../../../../common/src/components/Text";
import Heading from "../../../../common/src/components/Heading";
import FeatureBlock from "../../../../common/src/components/FeatureBlock";
import Container from "../../../../common/src/components/UI/Container";
import TeamSectionWrapper, { SocialLinks } from "./teamSection.style";

const TeamSection = ({
  row,
  col,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  memberName,
  designation,
  contentStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      markJson {
        TEAM_MEMBER {
          name
          designation
          id
          thumbnail_url {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          social_links {
            id
            icon
            url
          }
        }
      }
    }
  `);

  return (
    <TeamSectionWrapper id="team_section">
      <Container>
        <Box {...sectionHeader}>
          <Text content="TEAM MEMBER" {...sectionSubTitle} />
          <Heading
            content="Meet the founding team including a former SMMA founder (Social Media Marketing Agency)"
            {...sectionTitle}
          />
        </Box>
        <Box className="row" {...row} style={{ justifyContent: "center" }}>
          {Data.markJson.TEAM_MEMBER.map((member, index) => (
            <Box className="col" {...col} key={`team_key-${index}`}>
              <FeatureBlock
                id={`member-${member.id}`}
                className="team__member"
                icon={
                  <Image
                    fluid={
                      (member.thumbnail_url !== null) | undefined
                        ? member.thumbnail_url.childImageSharp.fluid
                        : {}
                    }
                    alt={`Team member photo ${member.id}`}
                    className="member__photo"
                  />
                }
                contentStyle={contentStyle}
                title={<Heading content={member.name} {...memberName} />}
                description={
                  <Fragment>
                    <Text content={member.designation} {...designation} />
                    <SocialLinks>
                      {member.social_links.map((social, index) => (
                        <a
                          href={social.url}
                          key={`profile_id-${index}`}
                          aria-label={social.icon}
                        >
                          <i className={social.icon}></i>
                        </a>
                      ))}
                    </SocialLinks>
                  </Fragment>
                }
              />
            </Box>
          ))}
        </Box>
      </Container>
    </TeamSectionWrapper>
  );
};

// TeamSection style props
TeamSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  memberName: PropTypes.object,
  designation: PropTypes.object,
};

// TeamSection default style
TeamSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ["40px", "56px"],
  },
  // sub section default style
  sectionSubTitle: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "14px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#ff4362",
    mb: "10px",
  },
  // section title default style
  sectionTitle: {
    textAlign: "center",
    fontSize: ["20px", "24px"],
    fontWeight: "400",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0",
    width: "540px",
    ml: "auto",
    mr: "auto",
  },
  // Team member row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // Team member col default style
  col: {
    width: [1, 1 / 2, 1 / 3, 1 / 3],
    pl: "15px",
    pr: "15px",
    mb: "30px",
  },
  // Team member content default style
  contentStyle: {
    textAlign: "center",
    mt: "25px",
  },
  // Team member memberName default style
  memberName: {
    fontSize: ["18px", "18px", "16px", "20px"],
    fontWeight: "400",
    color: "#0f2137",
    lineHeight: "1.5",
    mb: "8px",
    letterSpacing: "-0.020em",
  },
  // Team member description default style
  designation: {
    fontSize: ["15px", "16px", "14px", "17px"],
    lineHeight: "1",
    color: "rgba(15, 33, 55, 0.6)",
    mb: 0,
  },
};

export default TeamSection;
