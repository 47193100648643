import React from "react";
import PropTypes from "prop-types";
import Icon from "react-icons-kit";
import Fade from "react-reveal/Fade";
import { openModal, closeModal } from "@redq/reuse-modal";
import Box from "../../../../common/src/components/Box";
import Text from "../../../../common/src/components/Text";
import Heading from "../../../../common/src/components/Heading";
import Button from "../../../../common/src/components/Button";
import Image from "../../../../common/src/components/Image";
import Container from "../../../../common/src/components/UI/Container";
import TiltShape from "../TiltShape";
import {
  BannerWrapper,
  DiscountWrapper,
  DiscountLabel,
  VideoModal,
  PlayButton,
  VideoWrapper,
} from "./banner.style";
import BannerImage from "../../../../common/src/assets/image/mark/markBanner.png";
import { ic_play_circle_filled } from "react-icons-kit/md/ic_play_circle_filled";
import { play } from "react-icons-kit/entypo/play";
import { openPopupWidget } from "react-calendly";
import { sendEvent } from "../../../lib/analytics/events";

// close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const ModalContent = () => (
  <VideoWrapper>
    <iframe
      title="Video"
      src="https://mark-landing.s3.us-east-2.amazonaws.com/Mark+1.3+Promo.mp4"
      frameBorder="0"
    />
  </VideoWrapper>
);

const BannerSection = ({
  row,
  contentWrapper,
  discountAmount,
  discountText,
  title,
  description,
  imageWrapper,
  buttonWrapper,
  button,
  fillButton,
}) => {
  // modal handler
  const handleVideoModal = () => {
    sendEvent("play_promo_video");
    openModal({
      config: {
        className: "video-modal",
        disableDragging: true,
        width: "100%",
        height: "100%",
      },
      component: ModalContent,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };
  /*
  const videoBanner = false;

  const discount = false;

  const calendars = [
    "https://calendly.com/markbeta/demo",
    "https://calendly.com/betamark/30min",
  ];

  const prefill = {
    email: "",
    firstName: "Jon",
    lastName: "Snow",
  };
  const utm = {};

  const pageSettings = {
    backgroundColor: "ffffff",
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: "00a2ff",
    textColor: "4d5055",
  };

  const getRandomCalendar = () => {
    //const shuffledCalendars = calendars.sort(() => 0.5 - Math.random());
     const url = shuffledCalendars[0];
    openPopupWidget({ url, prefill, pageSettings, utm });
  };
*/
  const redirectToFreeTrial = () => {
    sendEvent("click_free_trial", { source: "banner_section" });
    const siteUrl =
      typeof window !== undefined && typeof window.location !== undefined
        ? window.location.protocol + "//app." + window.location.host
        : process.env.APP_URL;
    window.location = `https://apps.shopify.com/mark`;
  };

  return (
    <BannerWrapper id="banner_section">
      <TiltShape className="banner-shape" />
      <Container>
        <Box {...row}>
          <Box {...contentWrapper}>
            <DiscountWrapper>
              <DiscountLabel>
                <Text {...discountAmount} content="Lifetime discount" />
                <Text {...discountText} content="by joining the beta now" />
              </DiscountLabel>
            </DiscountWrapper>
            <Heading
              {...title}
              content="The platform that makes your Facebook Ads profitable"
            />
            <Text
              {...description}
              content="Focus your time on your customers, not on your campaign configuration. Be like us, use Mark!"
            />
            <Box {...buttonWrapper}>
              <Button
                {...fillButton}
                title="Try for FREE!"
                onClick={() => redirectToFreeTrial()}
                style={{ cursor: "pointer" }}
              />
            </Box>

            <Text
              {...description}
              style={{ color: "lightblue" }}
              content="Get free private mentoring + a lifetime discount by joining the beta."
            />
          </Box>
          <Box {...imageWrapper} style={{ cursor: "pointer" }}>
            <Fade bottom>
              <VideoModal onClick={handleVideoModal}>
                <Image src={BannerImage} alt="banner image" />
                <PlayButton tabIndex="0">
                  <Icon icon={play} size={40} />
                </PlayButton>
              </VideoModal>
            </Fade>
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  contentWrapper: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  imageWrapper: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
  fillButton: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  contentWrapper: {
    width: ["100%", "100%", "80%", "55%", "50%"],
    mb: "40px",
  },
  title: {
    fontSize: ["24px", "32px", "40px", "42px", "46px"],
    fontWeight: "700",
    color: "#fff",
    letterSpacing: "-0.025em",
    mb: ["20px", "25px", "25px", "25px", "25px"],
    lineHeight: "1.2",
    textAlign: "center",
  },
  description: {
    fontSize: ["15px", "16px", "16px", "16px", "16px"],
    color: "#fff",
    lineHeight: "1.75",
    mb: "0",
    textAlign: "center",
  },
  discountAmount: {
    fontSize: ["13px", "14px", "14px", "14px", "14px"],
    fontWeight: "700",
    color: "#fff",
    mb: 0,
    as: "span",
    mr: "0.4em",
  },
  discountText: {
    fontSize: ["13px", "14px", "14px", "14px", "14px"],
    fontWeight: "400",
    color: "#fff",
    mb: 0,
    as: "span",
  },
  fillButton: {
    type: "button",
    fontSize: ["13px", "14px"],
    fontWeight: "600",
    borderRadius: "4px",
    p: ["0px 15px", "8px 22px"],
    colors: "secondaryWithBg",
    minWidth: ["auto", "150px"],
    height: ["40px", "46px"],
    minHeight: "auto",
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: "center",
    mt: "35px",
  },
  button: {
    type: "button",
    fontSize: ["13px", "14px"],
    fontWeight: "600",
    borderRadius: "4px",
    p: ["0px 15px", "8px 22px"],
    color: "#fff",
    colors: "secondary",
    height: ["40px", "46px"],
    minHeight: "auto",
  },
};

export default BannerSection;
