import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "../../../../common/src/components/Box";
import Text from "../../../../common/src/components/Text";
import Heading from "../../../../common/src/components/Heading";
import Button from "../../../../common/src/components/Button";
import Container from "../../../../common/src/components/UI/Container";
import { openPopupWidget } from "react-calendly";
import NewsletterWrapper, { ContactFormWrapper } from "./newsletter.style";
import { sendEvent } from "../../../lib/analytics/events";

const Newsletter = ({
  sectionWrapper,
  textArea,
  buttonArea,
  buttonStyle,
  title,
  description,
  position,
}) => {
  /*
  const calendars = [
    "https://calendly.com/markbeta/demo",
    "https://calendly.com/betamark/30min",
  ];

  const prefill = {
    email: "",
    firstName: "Jon",
    lastName: "Snow",
  };
  const utm = {};

  const pageSettings = {
    backgroundColor: "ffffff",
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: "00a2ff",
    textColor: "4d5055",
  };

  const getRandomCalendar = () => {
    const shuffledCalendars = calendars.sort(() => 0.5 - Math.random());

    const url = shuffledCalendars[0];
    sendEvent("click_free_trial", {
      source: "free_trial_section",
      position: position,
    });
    //openPopupWidget({ url, prefill, pageSettings, utm });
  };*/

  const redirectToFreeTrial = () => {
    sendEvent("click_free_trial", {
      source: "free_trial_section",
      position: position,
    });
    const siteUrl =
      typeof window !== undefined && typeof window.location !== undefined
        ? window.location.protocol + "//app." + window.location.host
        : process.env.APP_URL;
    window.location = `https://apps.shopify.com/mark`;
  };

  return (
    <Box {...sectionWrapper} as="section" id="email_section">
      <Container>
        <NewsletterWrapper>
          <Box {...textArea}>
            <Heading content="Get access now" {...title} />
            <Text
              content="The platform is ready-to-use in beta mode, join right now and get a lifetime discount and free private mentoring!"
              {...description}
            />
          </Box>

          <Box {...buttonArea}>
            <ContactFormWrapper>
              <Button
                {...buttonStyle}
                title="GET ACCESS (Requires free sign up)"
                onClick={() => redirectToFreeTrial()}
              />
            </ContactFormWrapper>
            <ContactFormWrapper>
              <Text
                content="No credit card required."
                {...description}
                style={{ paddingLeft: 100 }}
              />
            </ContactFormWrapper>
          </Box>
        </NewsletterWrapper>
      </Container>
    </Box>
  );
};

Newsletter.propTypes = {
  sectionWrapper: PropTypes.object,
  textArea: PropTypes.object,
  buttonArea: PropTypes.object,
  buttonStyle: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
};

Newsletter.defaultProps = {
  sectionWrapper: {},
  textArea: {
    mb: ["40px", "40px", "40px", "0", "0"],
    pr: ["0", "0", "0", "80px", "100px"],
  },
  title: {
    fontSize: ["18px", "20px", "22px", "24px", "26px"],
    fontWeight: "500",
    color: "#fff",
    lineHeight: "1.34",
    mb: ["14px", "14px", "14px", "14px", "13px"],
    textAlign: ["center", "center", "center", "left", "left"],
    letterSpacing: "-0.025em",
  },
  description: {
    fontSize: ["14px", "14px"],
    fontWeight: "400",
    color: "#fefefe",
    lineHeight: "1.7",
    mb: 0,
    textAlign: ["center", "center", "center", "left", "left"],
  },
  buttonArea: {
    zIndex: 1,
  },
  buttonStyle: {
    type: "button",
    fontSize: "14px",
    fontWeight: "700",
    pl: "30px",
    pr: "30px",
    colors: "secondaryWithBg",
    color: "#03103b",
  },
};

export default Newsletter;
