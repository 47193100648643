import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Icon } from "react-icons-kit";
import { angleRight } from "react-icons-kit/fa/angleRight";
import Container from "../../../../common/src/components/UI/Container";
import BlogPost from "../../../../common/src/components/BlogPost";
import Box from "../../../../common/src/components/Box";
import Text from "../../../../common/src/components/Text";
import Link from "../../../../common/src/components/Link";
import Heading from "../../../../common/src/components/Heading";
import SectionWrapper, { ContentWrapper } from "./newsFeed.style";

const NewsFeed = ({ sectionHeader, sectionSubTitle, sectionTitle }) => {
  const Data = useStaticQuery(graphql`
    query {
      markJson {
        NEWS_FEED {
          id
          title
          excerpt
          link
          image {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  return (
    <SectionWrapper id="blog_section">
      <Container>
        <Box {...sectionHeader}>
          <Text content="LATEST POSTS" {...sectionSubTitle} />
          <Heading
            content="Discover our recent updates and blog posts"
            {...sectionTitle}
          />
        </Box>
        <ContentWrapper>
          {Data.markJson.NEWS_FEED.map((news) => (
            <BlogPost
              key={news.id}
              thumbUrl={news.image.childImageSharp.fluid.src}
              title={news.title}
              excerpt={news.excerpt}
              link={
                <Link href={news.link} className="excerptLink">
                  Learn More
                  <Icon icon={angleRight} />
                </Link>
              }
            />
          ))}
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

// TeamSection default style
NewsFeed.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ["40px", "56px"],
  },
  // sub section default style
  sectionSubTitle: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "14px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#ff4362",
    mb: "10px",
  },
  // section title default style
  sectionTitle: {
    textAlign: "center",
    fontSize: ["20px", "24px"],
    fontWeight: "400",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0",
  },
};

export default NewsFeed;
